import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { toDataContext } from "./units.js";
import { CallReceived, CallMade } from "@material-ui/icons/";
import { green } from "@material-ui/core/colors/";
import DataGraph from "./DataGraph";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();
  const theme = useTheme();
  var { download = [0], upload = [0], unit = "B" } = props.network;

  //dynamically finds the appropriate unit and the converted value
  const downloadCaption = toDataContext(unit, download[download.length - 1]);
  const uploadCaption = toDataContext(unit, upload[upload.length - 1]);
  //sets the minimum value the graph chieling can drop to
  //ie 500,000 => graph's top will not drop below 500kb
  const GRAPH_MIN_CIELING = 500000;
  const data = {
    download: {
      name: "download",
      color: theme.palette.primary.main,
      values: download,
    },
    upload: {
      name: "upload",
      color: green["A400"],
      values: upload,
    },
  };

  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      <Grid item container xs={12}>
        <Grid item xs={4}>
          <Typography variant="h6">Network</Typography>
        </Grid>

        {/* Current stats */}
        <Grid item container xs={8} direction="column">
          {/* Each row */}
          <Grid item container justify="flex-end">
            {/* Icon and stats */}
            <CallReceived style={{ color: data.download.color }} />

            <Typography variant="body1">{`${downloadCaption}/s`}</Typography>
          </Grid>

          <Grid item container justify="flex-end">
            {/* Icon and stats */}
            <CallMade style={{ color: data.upload.color }} />

            <Typography variant="body1">{`${uploadCaption}/s`}</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Graph */}
      <Grid item xs={12}>
        <DataGraph
          data={Object.values(data)}
          range={[
            0,
            (maxRange) =>
              Math.max(maxRange + 0.05 * maxRange, GRAPH_MIN_CIELING),
          ]}
        />
      </Grid>
    </Grid>
  );
};
