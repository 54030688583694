import axios from "axios";
import { actionOK, actionError } from "./utils";
import * as api from "./api";
import { fetchBacklogList, fetchUserList } from "./shows";

/**
 *
 * @param {String[]} shows A list of shows to remove;
 */
export const deleteFromBacklog = (shows) => async (dispatch) => {
  try {
    await axios.delete(api.backlog, { data: { shows: shows } });
    dispatch(fetchBacklogList());
    actionOK(dispatch, "Removed from backlog");
  } catch (err) {
    actionError("Could not remove show from backlog");
  }
};

export const moveToWatchlist = (shows) => async (dispatch) => {
  try {
    await axios.put(api.trackShow, {
      shows: shows,
      source: "library",
    });
    await axios.delete(api.backlog, { data: { shows: shows } });
    dispatch(fetchBacklogList());
    dispatch(fetchUserList());
    actionOK(dispatch, "Moved to watchlist");
  } catch (err) {
    actionError(dispatch, err, "Could not move show to watchlist");
  }
};

export const addToBacklogFromWatchlist = (shows) => async (dispatch) => {
  try {
    await axios.put(api.backlog, { shows: shows });
    await axios.delete(api.deleteShow, { data: { shows: shows } });
    dispatch(fetchBacklogList());
    dispatch(fetchUserList());
    actionOK(dispatch, "Moved to backlog");
  } catch (err) {
    actionError(dispatch, err, "Could not move show to backlog");
  }
};

export const addToBacklog = (shows) => async (dispatch) => {
  try {
    await axios.put(api.backlog, { shows: shows });
    dispatch(fetchBacklogList());
    actionOK(dispatch, "Added to backlog");
  } catch (err) {
    actionError(dispatch, err, "Could not add to backlog");
  }
};
