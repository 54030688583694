import React from "react";
import {
  List,
  Typography,
  Divider,
  Container,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import NCEntry from "./NCEntry";
import { deleteAllNCMessage } from "../../store/actions/index";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
  },
  header: {
    height: theme.spacing(6),
    padding: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
  contentWindow: {
    height: 400,
    overflowY: "scroll",
  },
  noContentPane: {
    height: 400,
    width: 444,
  },
  title: {
    flex: 1,
  },
}));

export default (props) => {
  const classes = useStyles();
  const messages = useSelector((state) => state.serverMessages);
  const dispatch = useDispatch();

  const clearAllMessages = () => dispatch(deleteAllNCMessage());
  const Content = () => {
    if (messages.length > 0) {
      return (
        <List style={{ width: "100%" }} className={classes.contentWindow}>
          {[...messages].reverse().map((message, i) => (
            <NCEntry
              timestamp={message.timestamp}
              serverId={message.id}
              key={i}
              type={message.type}
              message={message.message}
            />
          ))}
        </List>
      );
    } else {
      return <div className={classes.noContentPane} />;
    }
  };

  return (
    <Container className={classes.container} maxWidth="xs">
      <AppBar position="static" color="default">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="subtitle1" className={classes.title}>
            Notifications
          </Typography>
          <Button onClick={clearAllMessages}>Clear All</Button>
        </Toolbar>
      </AppBar>

      <Divider />
      <Content />
    </Container>
  );
};
