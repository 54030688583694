import React, { useState, Fragment } from "react";

import { Menu, MenuItem, IconButton } from "@material-ui/core";

import { MoreVert as VertDotsIcon } from "@material-ui/icons/";

import { info, getEpisodeList, serverInfo } from "../../store/actions/index";
import { useDispatch } from "react-redux";

export default (props) => {
  const { variant, showName } = props;

  const [location, setLocation] = useState(null);
  const dispatch = useDispatch();

  //handlers for this menu
  const handleClick = (e) => setLocation(e.currentTarget);
  const handleClose = () => setLocation(null);

  //preset actions that could be in this expanded menu

  const moreInfo = {
    name: "More Info",
    action: () => {
      info(showName);
      handleClose();
    },
  };

  const downloadEpisodes = {
    name: "Download Episodes",
    action: () => {
      dispatch(getEpisodeList(showName));
      handleClose();
    },
  };

  const showPath = {
    name: "Show Location",
    action: () => {
      dispatch(serverInfo(showName));
      handleClose();
    },
  };

  const actions = {
    watchlist: [moreInfo, showPath, downloadEpisodes],
    library: [moreInfo, showPath],
    online: [moreInfo],
    backlog: [moreInfo],
  };

  //use the variant to select the group of actions, and conver them to menuItems
  const selectedActions = actions[variant].map((command, i) => (
    <MenuItem key={i} onClick={command.action}>
      {command.name}
    </MenuItem>
  ));

  return (
    <Fragment>
      {/* the button for the menu */}
      <IconButton onClick={handleClick}>
        <VertDotsIcon />
      </IconButton>

      {/* the menu itself */}
      <Menu anchorEl={location} open={Boolean(location)} onClose={handleClose}>
        {selectedActions}
      </Menu>
    </Fragment>
  );
};
