import React, { useMemo } from "react";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { green, yellow, teal, lime } from "@material-ui/core/colors";

import { APP_PALETTE } from "../../config/config";

export default (props) => {
  const darkTheme = useSelector((state) => state.settings.darkTheme);

  //inject darkTheme flag into theme pulled from config file
  const palette = { ...APP_PALETTE, type: darkTheme ? "dark" : "light" };

  const baseTheme = useMemo(
    () =>
      createMuiTheme({
        shape: {
          borderRadius: 8,
        },
        palette: palette,
        showHighlights: {
          immediate: darkTheme ? teal["900"] : green["A100"],
          relevant: darkTheme ? lime["800"] : yellow["A100"],
        },
        graphDefaultColors: {
          // primary : [ "#89D2DC","#2364AA", "#6564DB",  "#1D3461", "#101D42"],
          primary: ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF5520"],
          grey: "#999999",
        },
      }),
    [darkTheme, palette]
  );

  return (
    <MuiThemeProvider theme={baseTheme}>{props.children}</MuiThemeProvider>
  );
};
