import React, { useState, useEffect } from "react";

import { Grid, TextField, Fade, Select, MenuItem } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

//run the search algorithm 1 second after typing has stopped
const DELAY_RUN_SEARCH = 250;

export default (props) => {
  const classes = useStyles();
  const searchKey = props.searchKey ? props.searchKey : "(no key provided)";
  const searchMethod = props.searchMethod;

  const [searchWord, setSearchWord] = useState("");

  useEffect(() => {
    const timerID = setTimeout(() => {
      searchMethod(searchWord);
    }, DELAY_RUN_SEARCH);
    return () => clearTimeout(timerID);
  }, [searchWord, searchMethod]);

  const searchOpt = props.searchOptions
    ? props.searchOptions
    : {
        currentValue: "",
        handler: (v) => console.log(`Selected ${v}`),
        choices: [],
      };

  //guarenteed to have one by this point
  const handleSourceChange = (event) => {
    searchOpt.handler(event.target.value);
  };

  const handleSearchWordChange = (event) => setSearchWord(event.target.value);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={1} className={classes.verticalCenter}>
        <SearchIcon />
      </Grid>
      <Grid item xs={9}>
        <TextField
          placeholder={`search by ${searchKey}`}
          onChange={handleSearchWordChange}
          fullWidth
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
      <Grid item xs={2} className={classes.alignRight}>
        <Fade
          in={
            Boolean(props.searchOptions) &&
            props.searchOptions.choices.length > 0
          }
          unmountOnExit
        >
          <Select
            value={searchOpt.currentValue}
            onChange={handleSourceChange}
            disableUnderline
          >
            {searchOpt.choices.map((c, i) => (
              <MenuItem key={i} value={c.value}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </Fade>
      </Grid>
    </Grid>
  );
};
