import React, { useState } from "react";
import { IconButton, Badge, Popover } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";
import NCPane from "./NCPane.js";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  badgeIcon: {
    margin: 0,
  },
}));

export default (props) => {
  const messageCount = useSelector((state) => state.serverMessages.length);
  const [NCLocation, setNCLocation] = useState(null);

  const setNC = (event) => setNCLocation(event.currentTarget);
  const clearNC = (event) => setNCLocation(null);

  const classes = useStyles();

  return (
    <React.Fragment>
      <IconButton onClick={setNC} className={classes.button} color="inherit">
        {messageCount > 0 ? (
          <Badge
            className={classes.badgeIcon}
            badgeContent={messageCount}
            color="secondary"
          >
            <Notifications />
          </Badge>
        ) : (
          <Notifications />
        )}
      </IconButton>

      <Popover
        open={Boolean(NCLocation)}
        anchorEl={NCLocation}
        onClose={clearNC}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NCPane />
      </Popover>
    </React.Fragment>
  );
};
