import React from "react";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from "@material-ui/core/";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { calcDeltaTime, isCurrent, isWithin24H } from "../../utils/util";

import ShowMenu from "./ShowMenu";

const useStyles = makeStyles((theme) => ({
  mainArea: {
    padding: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();

  const highlights = useTheme().showHighlights;

  const determineColor = (date) => {
    if (isCurrent(date)) return highlights.immediate;
    if (isWithin24H(date)) return highlights.relevant;
    return null;
  };

  //add any special coloring to the show
  const color = props.date ? determineColor(new Date(props.date)) : null;
  const deltaTime = props.date ? calcDeltaTime(new Date(props.date)) : null;

  //convert other delims to spaces
  const delims = ["_"];
  var name = props.name;
  delims.forEach((delim) => {
    name = props.name.replace(new RegExp(delim, "g"), " ");
  });

  return (
    <React.Fragment>
      <ListItem
        divider={true}
        className={classes.mainArea}
        key={props.name}
        role={undefined}
        button={true}
        onClick={props.click}
        style={color ? { backgroundColor: color } : null}
      >
        <Checkbox checked={props.checked} tabIndex={-1} disableRipple />
        <ListItemText primary={name} secondary={deltaTime} />
        <ListItemSecondaryAction>
          <ShowMenu variant={props.location} showName={name} />
        </ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
};
