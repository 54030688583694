import * as actionTypes from "../actions/actionTypes";

const initialState = {
  darkTheme: false,
};

const setDarkTheme = (state, action) => {
  return {
    ...state,
    darkTheme: action.useDarkTheme,
  };
};

/**
 * Reducer for modifying app settings
 * @param  {[Object]} [state=initialState] The current state of the settings
 * @param  {Object} action               The action to modify the state
 * @param  {Boolean} action.useDarkTheme Whether to toggle darkmode
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DARK_THEME:
      return setDarkTheme(state, action);
    default:
      return state;
  }
};

export default reducer;
