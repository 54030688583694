import React, { useState, useEffect } from "react";
import PopupBar from "../../Components/PopupBar/PopupBar";
import {
  fetchNotes,
  saveNote as saveNoteAction,
  modifyNote,
  deleteNote,
} from "../../store/actions/";
import { Paper, Grid, TextField, Container, Fab } from "@material-ui/core/";
import Composer from "./Composer";
import NoteThumb from "./NoteThumb";
import { Add, Search } from "@material-ui/icons/";

import { makeStyles } from "@material-ui/core/styles";
//actions and redux
import { useSelector, useDispatch } from "react-redux";

import { searchWordFilter } from "../../utils/util";

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    width: "100%",
  },
  searchBarContainer: {
    width: "100%",
    marginBottom: theme.spacing(5),
  },
  searchBar: {
    padding: theme.spacing(1),
  },
  fab: {
    height: 48,
    width: 48,
  },
  toolbar: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

export default (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //TODO remove 1 level of the state in redux
  const notes = useSelector((state) => state.notes.notes);
  const [searchWord, setSearchWord] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);

  useEffect(() => {
    dispatch(fetchNotes());
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchWord(e.target.value);
  };

  const genHandleNoteSelected = (note) => () => {
    setSelectedNote(note);
  };

  const handleClose = () => {
    setSelectedNote(null);
  };
  //handling changes made in the edior
  const handleEditNoteContent = (e) => {
    //spread the old state into the new state, and then modify the changed content
    setSelectedNote({ ...selectedNote, content: e.target.value });
  };

  const saveNote = () => {
    //If there is a Id in the note, then
    if ("id" in selectedNote) {
      dispatch(modifyNote(selectedNote));
    } else {
      dispatch(saveNoteAction(selectedNote));
    }
    handleClose();
  };

  const deleteSelectedNote = () => {
    //if an ID exists, then teh server has a record of the note, and must be
    //deleted
    if ("id" in selectedNote) dispatch(deleteNote(selectedNote));
    //if this was a new note, then simply close it
    handleClose();
  };

  const composeNewNote = () => {
    setSelectedNote({ content: "" });
  };

  //Search Bar subcomponent
  const searchBar = (
    <Container maxWidth="sm" className={classes.searchBarContainer}>
      <Paper className={classes.searchBar}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <Search />
          </Grid>
          <Grid item xs={11}>
            <TextField
              placeholder="Search Notes"
              fullWidth
              onChange={handleSearch}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );

  const NoteThumbNails = () => {
    let searchResults = searchWordFilter(notes, "content", searchWord);
    return searchResults.map((note, i) => (
      <Grid key={i} item>
        <NoteThumb
          content={note.content}
          onClick={genHandleNoteSelected(note)}
        />
      </Grid>
    ));
  };

  return (
    <React.Fragment>
      <Grid
        direction="column"
        justify="center"
        alignItems="center"
        container
        className={classes.rootGrid}
      >
        <Grid item container xs={12}>
          {searchBar}
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          justify="center"
          alignItems="center"
        >
          <NoteThumbNails />
        </Grid>
      </Grid>

      <PopupBar on={true}>
        <Grid container justify="flex-end">
          <Grid item>
            <Fab
              onClick={composeNewNote}
              variant="round"
              className={classes.fab}
              color="primary"
            >
              <Add />
            </Fab>
          </Grid>
        </Grid>
      </PopupBar>

      {selectedNote ? (
        <Composer
          open={Boolean(selectedNote)}
          onClose={handleClose}
          onChange={handleEditNoteContent}
          save={saveNote}
          delete={deleteSelectedNote}
          note={selectedNote}
        />
      ) : null}
    </React.Fragment>
  );
};
