import * as actionTypes from "./actionTypes";

export const setActionConfirm = (title, message, action) => {
  return {
    type: actionTypes.SET_ACTION_TO_CONFIRM,
    title: title,
    message: message,
    action: action,
  };
};

export const clearActionConfirm = () => {
  //TODO::
  //try rendering just turning it off first
  //and when the element has confirmed off THEN it would send the action to clea the contents
  return { type: actionTypes.CLEAR_ACTION_TO_CONFIRM };
};
