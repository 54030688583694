import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@material-ui/core/";
import { Info, Warning, Cancel } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { deleteNCMessage } from "../../store/actions/index.js";
import { calcDeltaTime } from "../../utils/util";
const icons = {
  info: Info,
  warning: Warning,
};

const Entry = (props) => {
  const dispatch = useDispatch();
  const removeEntry = () => dispatch(deleteNCMessage(props.serverId));

  const Icon = icons[props.type];
  return (
    <ListItem>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={props.message}
        secondary={calcDeltaTime(new Date(props.timestamp))}
      />
      <ListItemIcon>
        <IconButton onClick={removeEntry}>
          <Cancel />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};

export default Entry;
