import React from "react";

import {
  ListSubheader,
  List,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  ListItem,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { setPreferences, logout } from "../../store/actions/index";
import { Brightness4, ExitToApp } from "@material-ui/icons";

export default (props) => {
  const dispatch = useDispatch();

  const darkTheme = useSelector((state) => state.settings.darkTheme);

  const handleToggleDarkTheme = () =>
    dispatch(setPreferences({ darkTheme: !darkTheme }));

  const handleLogout = () => dispatch(logout());

  return (
    <List>
      <ListSubheader disableSticky={true}>Profile</ListSubheader>
      <ListItem>
        <ListItemIcon>
          <Brightness4 />
        </ListItemIcon>
        <ListItemText primary="Dark Theme" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleToggleDarkTheme}
            checked={darkTheme}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </ListItem>
    </List>
  );
};
