import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PopupBar from "../../Components/PopupBar/PopupBar";
import Center from "../../Components/Spacing/Center";
import { Grid } from "@material-ui/core";

import {
  RemoveFromWatchlistButton,
  AddFromLibraryButton,
  TrackOnlineButton,
  AddToBacklogButton,
  AddToBacklogFromWatchListButton,
  DeleteFromBacklogButton,
  MoveToWatchListButton,
  CancelButton,
} from "./ActionBarButtons";

const useStyles = makeStyles((theme) => ({
  actionBar: {
    // The height is temporary
    display: "flex",
    height: "100%",
    width: "100%",
  },
}));

const BUTTON_LAYOUTS = {
  watchlist: [
    RemoveFromWatchlistButton,
    AddToBacklogFromWatchListButton,
    CancelButton,
  ],
  library: [AddFromLibraryButton, AddToBacklogButton, CancelButton],
  online: [TrackOnlineButton, AddToBacklogButton, CancelButton],
  backlog: [MoveToWatchListButton, DeleteFromBacklogButton, CancelButton],
};

export default (props) => {
  const { view, checked, clearSelected } = props;
  const classes = useStyles();

  //assign the correct buttons to render based on the tab selected
  let layout = BUTTON_LAYOUTS[view];

  //if layout has these custom buttons, decorate with clearChecked function
  //and bake in the list to operate on
  if (layout) {
    layout = layout.map((CustomButton, index) => (
      //grid size is the Grid Components' 12 position layout devided
      //by number of buttons
      <Grid key={index} item xs={Math.floor(12 / layout.length)}>
        <CustomButton checked={checked} clearChecked={clearSelected} />
      </Grid>
    ));
  }

  return (
    <PopupBar on={checked.length > 0}>
      <Center size="sm">
        <div className={classes.actionBar}>
          <Grid container spacing={3} justify="center" alignItems="center">
            {layout}
          </Grid>
        </div>
      </Center>
    </PopupBar>
  );
};
