import axios from "axios";
import { actionOK, actionError } from "./utils";

import * as api from "./api";
import * as actionTypes from "./actionTypes";

/**
 * Request to create a new account
 * @param {string} username the username of the account to create
 * @param {string} pass the password of the account to create
 * @param {string} secret the secret (if configured) to authorize account creation
 */
export const signup = (username, pass, secret) => async (dispatch) => {
  try {
    await axios.post(api.signup, {
      username: username,
      password: pass,
      secret: secret,
    });
    actionOK(dispatch, "ACcount Created");
  } catch (err) {
    actionError(dispatch, err, "Could not create account");
  }
};

/**
 * Auto login the user if the user has already logged in in the past
 * and the cookie is still live
 */
export const testSession = () => async (dispatch) => {
  try {
    await axios.get(api.testSession);
    dispatch(loginSuccess());
  } catch (err) {
    //Will error if there is no session, just ignore this case
  }
};

/**
 * Login the user
 * @param {String} user the username to login as
 * @param {String} pass the password for the user
 */
export const login = (user, pass) => async (dispatch) => {
  try {
    const data = {
      username: user,
      password: pass,
    };
    await axios.post(api.signin, data);
    dispatch(loginSuccess());
  } catch (err) {
    actionError(dispatch, err, "Invalid Credentials");
  }
};

/**
 * Action to logout the user
 */
export const logout = () => async (dispatch) => {
  try {
    await axios.delete(api.signout);
    dispatch(logoutSuccess());
  } catch (err) {
    actionError(dispatch, err, "Could not logout user");
  }
};

export const loginSuccess = () => {
  return { type: actionTypes.LOGIN_SUCCESS };
};
export const logoutSuccess = () => {
  return { type: actionTypes.LOGOUT_SUCCESS };
};
