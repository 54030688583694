import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  ListItem,
  LinearProgress,
  Typography,
  Grid,
  ListItemSecondaryAction,
} from "@material-ui/core/";

import DownloadEntryMenu from "./DownloadEntryMenu";

const useStyles = makeStyles((theme) => ({
  info: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();
  const isPaused = props.status === "STOPPED";

  return (
    <ListItem>
      <Grid
        item
        container
        direction="column"
        spacing={0}
        className={classes.info}
      >
        {/* The name and percentage over the loading bar */}
        <Grid item xs={12} container>
          <Grid item xs={10}>
            <Typography>{props.name}</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Typography>{`${props.percentage}%`}</Typography>
          </Grid>
        </Grid>
        {/* The loading bar */}
        <Grid item xs={12}>
          <LinearProgress
            color={isPaused ? "secondary" : "primary"}
            variant="determinate"
            value={props.percentage}
          />
        </Grid>
      </Grid>
      <ListItemSecondaryAction>
        <DownloadEntryMenu
          downloadInfo={{ id: props.id, isPaused: isPaused }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
