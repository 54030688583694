import axios from "axios";
import { actionError } from "./utils";
import * as api from "./api";
import * as actionTypes from "./actionTypes";

export const updateDownloadStatus = () => async (dispatch) => {
  try {
    const { data } = await axios.get(api.downloadProgress);
    dispatch(saveDownloadStatus(data));
  } catch (err) {
    actionError(dispatch, err, "Could not fetch status of active downloads", {
      notifyUser: false,
      logError: true,
    });
  }
};

export const saveDownloadStatus = (data) => {
  let parsedData = data.torrents.map((t) => ({
    ...t,
    percentage: Math.round(t.percentDone * 100),
  }));
  return {
    type: actionTypes.UPDATE_DOWNLOAD_STATUS,
    downloads: parsedData,
  };
};
