import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, TextField, Grid, Link } from "@material-ui/core/";
import SingleCardLayout from "../../Components/Sizing/SingleCardLayout.js";

import { signup } from "../../store/actions/index.js";
import { useDispatch } from "react-redux";
import { notification as notify } from "../../store/actions/notification";
import Theme from "../../Components/Themes/LoginTheme";
const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  buttonSection: {
    marginTop: theme.spacing(5),
  },
}));

export default (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [secret, setSecret] = useState("");

  const userChange = (e) => setUsername(e.target.value);
  const passwordChange = (e) => setPassword(e.target.value);
  const passConfirmChange = (e) => setPasswordConfirm(e.target.value);
  const secretChange = (e) => setSecret(e.target.value);

  const signupSubmit = (e) => {
    e.preventDefault();
    if (password !== passwordConfirm)
      return dispatch(notify("Passwords do not match"));
    else if (password.length < 6)
      return dispatch(notify("Passowrds must be at least 6 characters"));
    return dispatch(signup(username, password, secret));
  };

  const Title = (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h4">Account Creation</Typography>
      </Grid>
    </Grid>
  );

  const Fields = (
    <Grid
      item
      container
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} className={classes.input}>
        <TextField
          onChange={userChange}
          label="username"
          className={classes.textField}
        />
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          onChange={passwordChange}
          type="password"
          label="password"
          className={classes.textField}
        />
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          onChange={passConfirmChange}
          type="password"
          label="password confirm"
          className={classes.textField}
        />
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField
          onChange={secretChange}
          type="password"
          label="secret"
          className={classes.textField}
        />
      </Grid>
    </Grid>
  );

  const Buttons = (
    <Grid item container xs={12} className={classes.buttonSection}>
      <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
        <Link href="/">Back</Link>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button type="submit" variant="contained" color="primary">
          Create
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Theme>
      <SingleCardLayout>
        <form onSubmit={signupSubmit}>
          <Grid container direction="column" spacing={1}>
            {Title}
            {Fields}
            {Buttons}
          </Grid>
        </form>
      </SingleCardLayout>
    </Theme>
  );
};
