import React, { useState, useRef, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

/**
 * @typedef Option
 * @property {String} label The label of the action to display on the button
 * @property {value} value the value of the action. Gets passed back to onClick(value) when main button is pressed
 */

/**
 * @typedef Props
 * @property {Option[]} options the list of options to pass to the button
 * @property {Function} onSelect get's passed the value of the option
 * @property {Function} onClick a void function to run when the main button is clicked
 */

/**
 * @param {Props} props the props for the component
 */
export default (props) => {
  const options = props.options;
  // const buttonLabel = props.currentValue.label;
  // const buttonLabel = props.options[]

  const [openDropdown, setOpenDropdown] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("");
  const ref = useRef(null);

  //if the options change, default the first option as the new default, and fire the onselect handler to update parent
  useEffect(() => {
    props.onSelect(options[0]);
    setButtonLabel(options[0].label);
  }, []);

  const handleNewSelectedOption = (e, index) => {
    props.onSelect(options[index]);
    setButtonLabel(options[index].label);
    //close the dropdown
    setOpenDropdown(false);
  };

  //toggle dropdown if you click the expand button
  const handleToggle = () => {
    setOpenDropdown((prevState) => !prevState);
  };

  const handleDropdownClose = (e) => {
    if (ref.current && ref.current.contains(e.target)) {
      return;
    } else {
      setOpenDropdown(false);
    }
  };

  return (
    <Grid container direction="column" alignItems={props.align}>
      <Grid item xs={12}>
        <ButtonGroup variant="text" color="default" ref={ref} size="small">
          {/* The primary button that gets reassigned */}
          <Button onClick={props.onClick} size="small">
            {buttonLabel}
          </Button>
          {/* Opens and closes the dropdown  */}
          <Button color="default" size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={openDropdown} anchorEl={ref.current} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleDropdownClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(e) => handleNewSelectedOption(e, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
