import * as actionTypes from "../actions/actionTypes";

const defaultAction = () => console.log("This should not do anything");

const initialState = {
  open: false,
  title: "",
  message: "",
  action: defaultAction,
};

const clearAction = (state) => {
  return {
    ...state,
    open: false,
    title: "",
    message: "",
    action: defaultAction,
  };
};
const setAction = (state, action) => {
  return {
    ...state,
    open: true,
    title: action.title,
    message: action.message,
    action: action.action,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_ACTION_TO_CONFIRM:
      return clearAction(state);
    case actionTypes.SET_ACTION_TO_CONFIRM:
      return setAction(state, action);
    default:
      return state;
  }
};

export default reducer;
