import React from "react";

import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";

import SystemMonitorSidebar from "../SystemMonitor/SidebarLayout.js";

import ProfileActions from "../ProfileMenu/ProfileActions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBarSpacing: theme.mixins.toolbar,
  sideBarContents: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default (props) => {
  const internalClasses = useStyles();
  const { variant, classes } = props;

  const SideBarHeader = <div className={internalClasses.appBarSpacing} />;

  const navElements = props.nav.map((n, index) => (
    <ListItem key={index} button onClick={props.changeView(index)}>
      <ListItemIcon>{n.icon}</ListItemIcon>
      <ListItemText primary={n.name} />
    </ListItem>
  ));

  const actionElements = props.actions.map((action, index) => (
    <ListItem key={index} button={true} onClick={action.procedure}>
      <ListItemIcon>{action.icon}</ListItemIcon>
      <ListItemText primary={action.name} />
    </ListItem>
  ));

  const Navigation = (
    <List>
      <ListSubheader disableSticky={true}>Navigation</ListSubheader>
      {navElements}
    </List>
  );

  const ActionButtons = (
    <List>
      <ListSubheader disableSticky={true}>Actions</ListSubheader>
      {actionElements}
    </List>
  );

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      variant={variant}
      classes={classes}
    >
      <div className={internalClasses.sideBarContents}>{SideBarHeader}</div>
      <Divider />

      <div className={internalClasses.sideBarContents}>{Navigation}</div>
      <Divider />

      <div className={internalClasses.sideBarContents}>{ActionButtons}</div>
      <Divider />

      <SystemMonitorSidebar componentStyle={internalClasses.sideBarContents} />

      <div className={internalClasses.sideBarContents}>
        <ProfileActions />
      </div>
    </Drawer>
  );
};
