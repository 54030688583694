import * as actionTypes from "../actions/actionTypes";

const initialState = {
  sources: [],
};

const updateSources = (state, action) => {
  return { ...state, sources: action.sources };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_RSS_SOURCES:
      return updateSources(state, action);
    default:
      return state;
  }
};

export default reducer;
