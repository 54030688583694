import React, { useState, useEffect } from "react";

import { Paper, Grid, TextField, Fade } from "@material-ui/core";

import DropdownButton from "../../Components/Dropdown/DropdownButton";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { downloadLink, getIngestLocations } from "../../store/actions/index.js";

const useStyles = makeStyles((theme) => ({
  magnetLinkBar: {
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: "100%",
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const ingestDestinations = useSelector((state) => state.ingest);

  //TODO the default library should be sorted included on the server side
  //TODO rework the server side to return data as follows, then the server can seperate the resource from the name

  const [magnetLinkURL, setMagnetLinkURL] = useState("");
  //You don't need to wait for the to set the default, as that's implicit
  const [ingestLocation, setIngestLocation] = useState("");

  useEffect(() => {
    //get list of ingest locations on first load
    dispatch(getIngestLocations());
  }, []);

  const dropdownOptions = ingestDestinations.map((c) => ({
    label: `${c.name}`,
    value: c.resource,
  }));

  //updating the string to the link
  const handleMagnetURLTextField = (e) => setMagnetLinkURL(e.target.value);

  //sending the link to the server
  const handleMagnetLinkAdd = () => {
    dispatch(downloadLink(magnetLinkURL, ingestLocation));
    setMagnetLinkURL("");
  };

  const handleDropdownSelect = (selectedOption) => {
    setIngestLocation(selectedOption.value);
  };

  return (
    <Fade in={ingestDestinations.length > 0} unmountOnExit>
      <Paper className={classes.magnetLinkBar}>
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={classes.magetLinkSpacing}
        >
          <Grid item xs={8}>
            <TextField
              placeholder="Magnet Link"
              fullWidth
              InputProps={{ disableUnderline: true }}
              onChange={handleMagnetURLTextField}
              value={magnetLinkURL}
            />
          </Grid>

          <Grid item xs={4}>
            <DropdownButton
              options={dropdownOptions}
              onClick={handleMagnetLinkAdd}
              onSelect={handleDropdownSelect}
              align={"flex-end"}
            />
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );
};
