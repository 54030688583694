import React from "react";
import StorageViewer from "./StorageViewer";
import NetworkViewer from "./NetworkViewer";
import CpuViewer from "./CpuViewer.js";
import TempViewer from "./TempViewer.js";
import RamViewer from "./RamViewer.js";
import UptimeViewer from "./UptimeViewer.js";

import { List, Divider } from "@material-ui/core/";
import { useSelector } from "react-redux";

export default (props) => {
  const status = useSelector((state) => state.status.status);
  const { componentStyle } = props;

  return (
    <List disablePadding={true}>
      <div className={componentStyle}>
        <CpuViewer usage={status.cpu} />
      </div>
      <Divider />

      <div className={componentStyle}>
        <TempViewer temp={status.temp} />
      </div>
      <Divider />

      <div className={componentStyle}>
        <RamViewer ram={status.ram} />
      </div>
      <Divider />

      <div className={componentStyle}>
        <UptimeViewer timeString={status.time} />
      </div>
      <Divider />

      <div className={componentStyle}>
        <StorageViewer storage={status.storage} />
      </div>
      <Divider />

      <div className={componentStyle}>
        <NetworkViewer network={status.network} />
      </div>
      <Divider />
    </List>
  );
};
