import React from "react";
import path from "path";
import { useSelector, useDispatch } from "react-redux";
import { clearServerInfo, copyToClipboard } from "../../store/actions/index";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    height: theme.spacing(6),
  },
}));

export default () => {
  const dispatch = useDispatch();
  const s = useSelector((state) => state.shows);

  const classes = useStyles();

  //gets all the drives on the machine
  let drives = useSelector((state) => state.status).status.storage.map(
    (drive) => path.basename(drive.name)
  );
  //replace the root drive with "root"
  drives[drives.indexOf("")] = "root";

  const showName = s.selectedShow;
  //if there is nothing to display, exit here
  if (showName === null || showName === "") return null;

  //gets the proper index from the multilist to get the library
  const libraryIndex = s.lists.map((list) => list.viewName).indexOf("library");
  const libraryList = s.lists[libraryIndex].contents;
  //goes finds the show entry for the selectedShow
  const showDetails =
    libraryList[libraryList.findIndex((show) => show.title === showName)];

  if (!showDetails.path) {
    return (
      <Dialog
        open={Boolean(showName)}
        onClose={() => dispatch(clearServerInfo())}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle> {showName}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {`Could not find ${showName} in library`}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  //the fullPath to the show
  const fullPath = showDetails.path;
  //just which drive the show is located in
  //const location = drives[drives.findIndex(drive => showDetails.path.match(new RegExp(drive)))];

  const handleCopyButton = () => {
    dispatch(copyToClipboard(fullPath));
  };

  return (
    <Dialog
      open={Boolean(showName)}
      onClose={() => dispatch(clearServerInfo())}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{showName}</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <TextField
              variant="outlined"
              label="Path"
              fullWidth
              defaultValue={fullPath}
              disabled
            />
          </Grid>

          <Grid container item xs={2} alignItems="center">
            <Grid item xs={1}>
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>

            <Grid item xs={11}>
              <Button color="primary" onClick={handleCopyButton}>
                Copy
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
