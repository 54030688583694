import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearEpisodeList, downloadFile } from "../../store/actions/index.js";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core/";

export default (props) => {
  const dispatch = useDispatch();
  const episodes = useSelector((state) => state.episodeDownloader.episodes);
  const showName = useSelector((state) => state.episodeDownloader.showName);

  const handleClose = (event, reason) => {
    dispatch(clearEpisodeList());
  };

  const bindHandleRequestFile = (file) => () => {
    dispatch(downloadFile(showName, file));
  };

  const list = episodes.map((episodeName) => (
    <ListItem
      divider={true}
      key={episodeName}
      button={true}
      onClick={bindHandleRequestFile(episodeName)}
    >
      <ListItemText primary={episodeName} />
    </ListItem>
  ));

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={showName !== null}
      onClose={handleClose}
    >
      <DialogTitle>Download Files</DialogTitle>

      <DialogContent>
        <DialogContentText>Select which files to download</DialogContentText>
        <List>{list}</List>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
