import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  trackShow,
  deleteShow,
  deleteFromBacklog,
  moveToWatchlist,
  addToBacklog,
  addToBacklogFromWatchlist,
} from "../../store/actions/index";

const useStyles = makeStyles((theme) => ({
  button: {
    height: theme.spacing(4),
    width: "100%",
  },
}));

const ActionButton = (props) => {
  const { buttonInfo } = props;
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.button}
      color={buttonInfo.color}
      onClick={buttonInfo.click}
    >
      {buttonInfo.text}
    </Button>
  );
};

export const RemoveFromWatchlistButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Remove",
    color: "primary",
    click: () => {
      dispatch(deleteShow(checked));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const AddFromLibraryButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Add",
    color: "primary",
    click: () => {
      dispatch(trackShow(checked, "library"));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const TrackOnlineButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Track",
    color: "primary",
    click: () => {
      dispatch(trackShow(checked, "online"));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const AddToBacklogButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Backlog",
    color: "primary",
    click: () => {
      dispatch(addToBacklog(checked));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const AddToBacklogFromWatchListButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Backlog",
    color: "primary",
    click: () => {
      dispatch(addToBacklogFromWatchlist(checked));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const DeleteFromBacklogButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Remove",
    color: "primary",
    click: () => {
      dispatch(deleteFromBacklog(checked));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const MoveToWatchListButton = (props) => {
  const { checked, clearChecked } = props;
  const dispatch = useDispatch();
  const button = {
    text: "Track",
    color: "primary",
    click: () => {
      dispatch(moveToWatchlist(checked));
      clearChecked();
    },
  };
  return <ActionButton buttonInfo={button} />;
};

export const CancelButton = (props) => {
  const { clearChecked } = props;
  const button = {
    text: "Cancel",
    color: "secondary",
    click: clearChecked,
  };
  return <ActionButton buttonInfo={button} />;
};
