import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DataGraph from "./DataGraph";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
}));

export default (props) => {
  const { temp = [0] } = props;
  const theme = useTheme();
  const classes = useStyles();

  const data = {
    name: "cpu temperature",
    color: theme.palette.primary.main,
    values: temp,
  };
  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      <Grid item container>
        <Grid item xs={10}>
          <Typography variant="h6">CPU Temp</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right">
            {`${temp[temp.length - 1]}°C`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGraph data={data} range={[0, 100]} />
      </Grid>
    </Grid>
  );
};
